// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "application.scss";
import "favicon/all.js";

require("bootstrap");

require("bootstrap-datepicker");
import "select2";

require("@rails/ujs").start();
require("@rails/activestorage").start();

import("trix");
import("@rails/actiontext");

import("../src/channels");
//require("memberarea")

$(() => {
  $(".datepicker").datepicker({
    // format: 'dd.mm.yyyy',
    format: "yyyy-mm-dd",
    startDate: "-1y",
    language: "de",
  });

  $("#add-email-form").hide();

  // using () => here messes with this and breaks each().
  $(".show-add-email").each(function (index) {
    $(this).on("click", function (e) {
      $("#add-email-link").hide();
      $("#add-email-form").show();
      e.preventDefault();
    });
  });

  $(".cancel-add-email").each(function (index) {
    $(this).on("click", function () {
      $("#add-email-form").hide();
      $("#add-email-link").show();
    });
  });

  $("#ie-add-coverage").click(function () {
    const symbol = $("#_stock_symbol").val();
    if (!symbol) {
      return;
    }
    const ctAttrs = {
      primary: { label: "Neuvorstellung", color: "success" },
      update: { label: "Update", color: "primary" },
      "covering quit": { label: "Abschluss", color: "danger" },
    };
    const name = $("#_stock_name").val();
    const id = $("#_stock_id").val();
    const coverageType = $("#_coverage_type").children("option:selected").val();
    let coverage = "";
    let hidden_tag = "";
    const deleteBtn = `<a href='#' class='btn btn-sm btn-danger'>Entfernen</a>`;

    const coverageTag = `<span class='badge badge-${ctAttrs[coverageType].color}'>${ctAttrs[coverageType].label}</span>`;
    hidden_tag = `<input type='hidden' name='coverages[existing_stocks][]' value='${id}|${coverageType}'>`;

    coverage = `<p class='coverage' data-ie-coverage-symbol='${symbol}'>${hidden_tag}<strong>${symbol}</strong> <small>${name}</small> ${coverageTag} ${deleteBtn}</p>`;

    removeCoverageWithSameSymbol(symbol);

    $("#ie-coverage-list")
      .append(coverage)
      .find("a")
      .on("click", function () {
        $(this).parents("p").remove();
      });
    $("#_stock_symbol").val("");
    $("#_stock_name").val("");
    $("#_stock_id").val("");
    $("#_stock_sb").val([]).trigger("change");
  });

  function formatStockResults(state) {
    if (!state.id) {
      return $(state.text);
    }
    return $(
      `<strong>${state.symbol}</strong><br /><small>${state.name}</small>`,
    );
  }

  function removeCoverageWithSameSymbol(symbol) {
    $(`p[data-ie-coverage-symbol="${symbol}"]`).remove();
  }

  $("#scheduled_event_kind_title").select2();

  $("#_stock_sb")
    .select2({
      ajax: {
        url: "/editors/stock_search",
        dataType: "json",
      },
      templateResult: formatStockResults,
      tags: true,
      createTag: function (params) {
        var term = $.trim(params.term);

        if (term === "") {
          return null;
        }
      },
    })
    .on("select2:select", function (e) {
      const stock = e.params.data;

      $("#_stock_id").val(stock.id);
      $("#_stock_symbol").val(stock.symbol);
      $("#_stock_name").val(stock.name).attr("disabled", true);
      $("#_coverage_type option[value=update]").attr("selected", true);
    })
    .on("select2:open", (_e) => {
      document.querySelector(".select2-search__field").focus();
    });

  $("figure.image_resized")
    .each(function () {
      const $figure = $(this).addClass("image-zoomable");
      console.debug("figure", $figure);
    })
    .on("click", function () {
      $(this).toggleClass("image-zoomed");
    });

  $("#videos, .embed-video-category").on(
    "click",
    ".item a.embed-delayed",
    function (e) {
      const $a = $(this);
      const embed = $a.data("embed");
      if (embed) {
        $a.replaceWith(embed);
      }
      e.stopPropagation();
      e.preventDefault();
      return false;
    },
  );
});

// Mortans design:
$(document).ready(function () {
  $("#confirm-delete").on("show.bs.modal", function (e) {
    $(this).find(".btn-ok").attr("href", $(e.relatedTarget).data("href"));
  });

  $('[data-toggle="tooltip"]').tooltip();
});
